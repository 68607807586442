import React from "react"
import styled, { css } from "styled-components"
import device, { size } from "../components/Theme/devices"
import { GridLayout } from "../components/GridLayout"
import { StickyContainer, Sticky } from "react-sticky"
import { useMedia } from "use-media"

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (${device.tablet}) {
    flex-direction: row;
  }

  position: relative;
`

const commonStyles = css`
  padding: 150px 20px 30px;
  align-content: start;
  @media (${device.tablet}) {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 250px;
    max-width: 50%;
  }
`

export const Column = styled(GridLayout)`
  ${commonStyles};
`

const LeftColumn = styled(GridLayout)`
  ${commonStyles}
  @media (${device.tablet}) {
    padding-top: 170px;
    #stick > div {
      > :first-child {
        padding-top: 80px;
      }
      > :last-child {
        padding-bottom: 30px;
      }
    }
  }
`

type TwoColumnsLayoutProps = {
  children: (part: "left" | "right") => JSX.Element
}

export const TwoColumnLayout: React.FC<TwoColumnsLayoutProps> = props => {
  const isTablet = useMedia({ minWidth: size.tablet })
  const leftChildren = props.children("left")
  return (
    <StickyContainer>
      <Container>
        <LeftColumn greenish padBottom>
          {isTablet ? (
            <Sticky topOffset={250} isActive={isTablet}>
              {({ style }) => (
                <div style={style} id={"stick"}>
                  {leftChildren}
                </div>
              )}
            </Sticky>
          ) : (
            leftChildren
          )}
        </LeftColumn>

        <Column padBottom>{props.children("right")}</Column>
      </Container>{" "}
    </StickyContainer>
  )
}
