import React from "react"
import { graphql } from "gatsby"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { TwoColumnLayout } from "../layout/TwoColumnsLayout"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { FormBlockProps } from "../layout/DatoCmsContent/sections/Form/Form"
import { Favicon } from "../components/Favicon"

const FormPage: React.FC<any> = ({
  data: {
    datoCmsForm: { seoMetaTags, formLeftSide = [], ...formData },
  },
  path,
}) => {
  const isForThankYouPage = (path as string).endsWith("/submitted/")
  const formBlockData: FormBlockProps = {
    __typename: "DatoCmsFormBlock",
    form: formData,
    isForThankYouPage,
    thankYouPagePath: isForThankYouPage
      ? undefined
      : (path as string) + "submitted/",
  }
  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <Favicon />

      <TwoColumnLayout>
        {part =>
          part === "left" ? (
            <div>
              {/*@ts-ignore*/}
              {formLeftSide.map(content => (
                <DatoCmsContent
                  verticalMargins={"none"}
                  key={content.id}
                  halfWidth
                  {...content}
                />
              ))}
            </div>
          ) : (
            <>
              {/*@ts-ignore*/}
              <DatoCmsContent
                halfWidth
                verticalMargins={"none"}
                {...formBlockData}
              />
            </>
          )
        }
      </TwoColumnLayout>
    </>
  )
}

export default FormPage

export const query = graphql`
  query PageData($id: String) {
    datoCmsForm(id: { eq: $id }) {
      ...FormData
      ...FormLeftSideData
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
